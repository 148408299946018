import React, { Component } from "react";
 
class Photos extends Component {
  render() {
    return (
<div>
        <h3> <center> Here are some dishes from past events! </center> </h3>
        <div class="foods"> 
        <img src="1.jpg" alt="1"/>
        <img src="2.jpg" alt="2"/>
        <img src="3.jpg" alt="3"/>
        <img src="4.jpg" alt="4"/>
        <img src="5.jpg" alt="5"/>
        <img src="6.jpg" alt="6"/>
        </div>
        
    <div class="footer">
        
       <p> images taken from unsplash.com </p>
        
        </div>
    
</div>
    );
  }
}
 
export default Photos;