import React, { Component } from "react";
 
class Menu extends Component {
  render() {
    return (
      <div>
        <center> <h2>Menu</h2> </center>
        <h3>Entrees</h3>
        <ul>
          <li>Chicken Parmesan (20)</li>
        <p> description </p>
          <li>Chicken French (25)</li>
        <p> description </p>
          <li>Item 3 ($)</li>
        <p> description </p>
          <li>Item 4 ($)</li>
        <p> description </p>
          <li>Item 5 ($)</li>
        <p> description </p>
        </ul>
        <h3>Sides</h3>
         <ul>
          <li>Stuffed Mushrooms (6)</li>
        <p> with Italian sausage, stuffing, and cheese </p>
          <li>Fried Cauliflower ($)</li>
      <p> description </p>
          <li>Item 3 ($)</li>
      <p> description </p>
        </ul>
      </div>
    );
  }
}
 
export default Menu;