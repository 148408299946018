import React, { Component } from "react";
 import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Menu from "./Menu";
import Contact from "./Contact";

import Photos from "./Photos";
import About from "./About";

class Main extends Component {
  render() {
    return (
        <HashRouter>
        <div>
          <h1>Mama Lisa&apos;s Catering </h1>
          <ul className="header">
            <li><NavLink exact to="/">Home</NavLink></li>
            <li><NavLink to="/Menu">Menu</NavLink></li>
            <li><NavLink to="/photos">Gallery</NavLink></li>
            <li><NavLink to="/about">About</NavLink></li>
            <li><NavLink to="/contact">Contact Us</NavLink></li>
           
        
          </ul>
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route path="/Menu" component={Menu}/>
            <Route path="/contact" component={Contact}/>
        
        <Route path="/photos" component={Photos}/>
        <Route path="/about" component={About}/>
             
          </div>
        </div>
        </HashRouter>
    );
  }
}
 
export default Main;