import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
         <div>
<h2> <center> Contact Us </center> </h2>
       
        
        <div class="fb"> <img src="fb.png" alt="fb"/> </div> <p> </p>
        
        
        <a href="https://www.facebook.com/Mama-Lisas-Catering-199682657306629/?modal=admin_todo_tour">Like us on Facebook! </a>
        
        
        
        <p> </p>
        <p> </p>
        <p> Rochester, NY </p> 
        <p> Phone: 585-899-0556 </p> 
        <p> Email: lnicholson1@rochester.rr.com </p>
        
        </div>
        
  );
}
}
 
export default Contact;