import React, { Component } from "react";
 
class About extends Component {
  render() {
    return (
      <div>
        <center> <h2>About Us</h2> </center> <p></p> <p></p>
        
        <div class="about"> 
        <p> Vivamus consequat justo in metus commodo, a maximus elit condimentum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce auctor pulvinar tellus, ac tincidunt metus porttitor in. Etiam in nunc elit. Pellentesque cursus enim eget diam vestibulum, eu sollicitudin magna lobortis. Duis porta consequat nisi. Vivamus laoreet velit nec metus mollis dictum. Vivamus vel viverra mauris. Nulla iaculis, augue eu interdum ullamcorper, augue tellus condimentum diam, sed posuere mi est sit amet nunc. </p>
      
      <p> </p>
      <p> </p>
      <p> </p>
      
      <p> Donec mollis dictum tortor, eget maximus quam efficitur sed. Nulla sit amet tincidunt sapien, nec maximus elit. In ac magna eget velit pharetra pulvinar quis et nunc. Nam quam metus, pretium eget metus vel, auctor vulputate massa. Vestibulum suscipit malesuada tellus ut consequat. Suspendisse potenti. Nullam pellentesque odio ultrices porta laoreet. In blandit luctus justo. Curabitur imperdiet nunc tortor, ut rhoncus velit lobortis ut. Fusce vehicula vitae nisi non vehicula. Praesent commodo tellus nisi, non placerat massa convallis id. Ut efficitur sagittis lacinia. Nam condimentum tempor pellentesque. Curabitur tempor pulvinar dapibus. Suspendisse egestas ut tortor lacinia laoreet. Cras quis aliquam sapien. </p> </div>
      </div>
    );
  }
}
 
export default About;