import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
      <div>
       <center> <h2> Welcome </h2> </center>
        <div class="hello"> <center> <img src="6.jpg" alt="pasta"/> </center> </div> <p> </p><p> </p>
        <h2>Heading 1</h2> <p> </p><p> </p>
        <p>Cras facilisis urna ornare ex volutpat, et
        convallis erat elementum. Ut aliquam, ipsum vitae
        gravida suscipit, metus dui bibendum est, eget rhoncus nibh
        metus nec massa. Maecenas hendrerit laoreet augue
        nec molestie. Cum sociis natoque penatibus et magnis
        dis parturient montes, nascetur ridiculus mus.</p> <p> </p>
        
        <h2>Heading 2</h2>
        <p></p> <p> </p>
        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pulvinar magna arcu, et consectetur metus aliquam in. Quisque tristique convallis arcu volutpat tempor. Nullam placerat, velit id finibus efficitur, mi libero egestas lacus, et efficitur tellus risus quis sapien. Nulla facilisi. Praesent nec semper nisi, at pharetra augue. Nullam blandit est ligula. Morbi lacus est, porta ut eleifend et, dapibus sed leo. Ut rhoncus bibendum consectetur. Vestibulum pellentesque lorem non lectus commodo lobortis. Pellentesque enim elit, laoreet id iaculis at, rhoncus sit amet sem. Duis diam velit, pulvinar sit amet cursus vitae, bibendum quis lacus. </p>
 
    
      </div>
    );
  }
}
 
export default Home;